import { useState } from 'react';
import { Tag } from 'react-feather';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { BasePopover, Button, TagsPicker } from '~/components/UIElements';
import { ProjectContext } from '~/context/project';
import { TagsContext } from '~/context/tags';
import { useEntityContext, useFloatMenu } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { CGDocumentInterfaceDetailed } from '~/interfaces/entities';
import useCommonDocumentsTags from '~/modules/Documents/hooks/useCommonDocumentsTags';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { documentServices, tagsServices } from '~/services';
import { LabelValueOptionInterface } from '~/types/labelValueOption.interface';
import { getErrorCode } from '~/utils/getErrorCode';

interface TagDocumentsButtonMenuProps {
    visibleSelectedDocuments: CGDocumentInterfaceDetailed[];
    selectedDocumentIds: string[];
}

const TagDocumentsButtonMenu = ({
    visibleSelectedDocuments,
    selectedDocumentIds,
}: TagDocumentsButtonMenuProps) => {
    const intl = useIntl();
    const tagsMenu = useFloatMenu();

    const [loading, setLoading] = useState(false);

    const { tagOptions, getTags, transformedFolderStructure } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const commonDocumentTags = useCommonDocumentsTags({
        documents: visibleSelectedDocuments,
    });

    const shouldPreselectCommonTags =
        selectedDocumentIds.length <= visibleSelectedDocuments.length;

    const addTagsToFile = async (newTags: LabelValueOptionInterface[]) => {
        setLoading(true);

        const tagsToAdd = newTags.filter((t) => !t.isNew).map((t) => t.value);

        const tagsToCreate = newTags.filter((t) => t.isNew).map((t) => t.value);

        const tagsToRemove = shouldPreselectCommonTags
            ? commonDocumentTags.ids?.filter((t) => !tagsToAdd.includes(t))
            : [];

        if (tagsToCreate.length) {
            const tagPromises = tagsToCreate.map((t) =>
                tagsServices.createTag({
                    project_id: selectedProject?.id as string,
                    name: t,
                })
            );

            const response = await Promise.all(tagPromises);

            response.forEach(
                ([error, response]) =>
                    !error && tagsToAdd.push(response.data.id)
            );
        }

        const payload = {
            document_ids: selectedDocumentIds,
            add_tag_ids: tagsToAdd,
            remove_tag_ids: tagsToRemove,
        };

        const [error, response] =
            await documentServices.updateDocumentsTags(payload);

        setLoading(false);

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        toast.success(intl.formatMessage({ id: 'tags_updated_successfully' }));

        getTags();
        tagsMenu.handleMenu();
    };

    return (
        <BasePopover
            isOpen={tagsMenu.showMenu}
            positions={['bottom', 'left', 'right']}
            align="start"
            onClickOutside={() => {
                tagsMenu.setShowMenu(false);
            }}
            content={
                <TagsPicker
                    onApply={addTagsToFile}
                    tags={tagOptions}
                    selectedTags={
                        shouldPreselectCommonTags
                            ? commonDocumentTags.options
                            : []
                    }
                    loading={loading}
                    folderStructure={transformedFolderStructure}
                />
            }
        >
            <Button
                variant="secondary"
                onClick={tagsMenu.handleMenu}
                iconBefore={Tag}
                disabled={loading}
            >
                <span>
                    {intl.formatMessage({
                        id:
                            selectedDocumentIds.length > 1
                                ? 'tag_files'
                                : 'tag_file',
                    })}
                </span>
            </Button>
        </BasePopover>
    );
};

export default TagDocumentsButtonMenu;
