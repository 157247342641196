import { BaseTable } from '~/components/Tables';
import './index.scss';
import DocumentsTableHeader from '~/modules/Documents/components/DocumentsTable/DocumentsTableHeader';

const mainClass = 'document-insights-table';

const DocumentInsightsTable = ({
    onScrollTouchesBottom,
    documents,
    columns,
    paging,
    loading,
    setLoading,
    onAddNewColumn,
    handleColumnsOrder,
    reorderableColumns,
    onUpdateCallback,
    tableHeight,
    onDownloadFiles,
    onCopyLink,
    documentIds,
}) => {
    const {
        pageNumber,
        totalPages,
        onSelectItem,
        setSelectedAll,
        selectedItems,
    } = paging;

    const onScrollToBottom = () => {
        if (pageNumber + 1 === totalPages) return;

        onScrollTouchesBottom();
    };

    const resetSelectedItems = () => {
        onSelectItem('');
        setSelectedAll(false);
    };

    return (
        <div className={mainClass + ' animate__animated animate__fadeIn'}>
            <DocumentsTableHeader
                loading={loading}
                onDownloadFiles={() => onDownloadFiles(false)}
                onUpdateCallback={onUpdateCallback}
                documents={documents}
                paging={{ onSelectItem, selectedItems }}
                handleLoading={setLoading}
                resetSelectedItems={resetSelectedItems}
                onCopyLink={onCopyLink}
                documentIds={documentIds}
            />

            <BaseTable
                data={documents}
                columns={columns}
                loading={loading}
                onSelectionChange={paging.setSelectedItems}
                selectedItems={paging.selectedItems}
                onScrollToBottom={onScrollToBottom}
                onAddNewColumn={onAddNewColumn}
                tableHeight={tableHeight}
                handleColumnsOrder={handleColumnsOrder}
                reorderableColumns={reorderableColumns}
                minWidth={900}
            />
        </div>
    );
};

export default DocumentInsightsTable;
