import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import {
    ImageInterface,
    ImageViewerProviderInterface,
} from '~/interfaces/contexts';
import { getQueryParams } from '~/utils/getQueryParams';
import { commentsServices } from '../../services';
export const ImageViewerContext =
    createContext<ImageViewerProviderInterface | null>(null);

interface ImageViewerParams {
    insightImage: string;
    project: string;
    workspace: string;
}

const queryParams: ImageViewerParams | null = getQueryParams('params');

const ImageViewerProvider = ({ children }: PropsWithChildren) => {
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState<ImageInterface | null>(null);

    useEffect(() => {
        checkParams();
    }, []);

    const checkParams = () => {
        if (queryParams?.insightImage) {
            getImage(queryParams.insightImage);
        }
    };

    const getImage = async (insightId: string) => {
        setLoading(true);

        const [error, response] =
            await commentsServices.getCommentImage(insightId);

        setLoading(false);

        if (error) return;

        setImage({ blob: response });
    };

    return (
        <ImageViewerContext.Provider
            value={{
                loading,
                image,
                handleImage: setImage,
                closeViewer: () => setImage(null),
                getImage,
            }}
        >
            {children}
        </ImageViewerContext.Provider>
    );
};

export default ImageViewerProvider;
