import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IntlShape } from 'react-intl';
import { ProjectContext } from '~/context/project';
import { TopicsContext } from '~/context/topics';
import {
    useColumnsFilterContext,
    useEntityContext,
    useFlexTableHeight,
    useTable,
} from '~/hooks';
import useDynamicDocumentInsightsColumn from '~/hooks/useDynamicDocumentInsightsColumn';
import {
    ImageViewerProviderInterface,
    InsightsResultsView,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import InsightsSubheader from '~/pages/Insights/InsightsSubheader';
import { documentServices } from '~/services';
import replaceLocationState from '~/utils/replaceLocationState';
import PermissionContext from '~/context/permissions/PermissionContext';
import { ENTITIES } from '~/constants/entities';
import { PERMISSIONS } from '~/constants/memberPermissions';
import DocumentInsightsTable from '~/components/DocumentInsightsTable';
import {
    CGDocumentDynamicPropertyInterfaceDetailed,
    CGDocumentInsightInterface,
} from '~/interfaces/entities';
import { buildSortingParameters } from '../../helpers/insightsDataHandlers';
import parseColumnValues from '~/utils/parseColumnValues';
import { DOCUMENT_TABLE_POSITION_TYPE } from '~/constants/DocumentTablePositionTypes.enum';
import { generateDocumentInsightFilteredLink } from '~/utils/generateLinks';
import toast from 'react-hot-toast';
import { extractFilters } from '~/helpers/documentInsightsDataHandlers';
import { TagsContext } from '~/context/tags';
import { ImageViewerContext } from '~/context/imageViewer';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { NewDynamicColumnInterface } from '~/components/Tables/AddColumnHeader';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import getRawColumnValues from '~/helpers/getRawColumnValues';

const bufferDownloadTool = () => import('../../utils/downloadBuffer');
const documentExcelBuilder = () =>
    import('../../helpers/ExcelUtils/buildDocumentsViewExcel');

interface DocumentsResultsProps {
    intl: IntlShape;
    handleView: () => void;
}

const DocumentsResults: React.FC<DocumentsResultsProps> = ({
    intl,
    handleView,
}) => {
    const isFirstRender = useRef(true);

    // STATES
    const [documents, setDocuments] = useState<CGDocumentInsightInterface[]>(
        []
    );

    const [loading, setLoading] = useState(false);
    const [loadingScreenshots, setLoadingScreenshots] = useState(false);

    const [dynamicColumns, setDynamicColumns] = useState<{
        [key: string]: CGDocumentDynamicPropertyInterfaceDetailed;
    }>({});

    const { handleImage } =
        useEntityContext<ImageViewerProviderInterface>(ImageViewerContext);

    const { isAllowedTo } = useContext(PermissionContext);

    const { tags, tagsLoaded, groupedTags } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const canEdit = useMemo(
        () => isAllowedTo(ENTITIES.DOCUMENTS, PERMISSIONS.EDIT),
        [isAllowedTo]
    );

    // filters by column
    const {
        columnValues,
        columnFilters,
        sortParams,
        filterParams,
        handleColumnValues,
        onApplyFilter,
        resetAllFilters,
        resetFilterColumn,
    } = useColumnsFilterContext();

    const previewMode = useMemo(
        () => columnFilters.some((c) => c.column === 'ids'),
        [columnFilters]
    );

    const { objectMappedTopics } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { selectedWorkspace } = useWorkspaces();

    const {
        pageNumber,
        pageSize,
        onSelectItem,
        setTotalItems,
        totalPages,
        selectedAll,
        selectedItems,
        onPageChange,
        setSelectedAll,
        setSelectedItems,
    } = useTable({ multipleSelect: true });

    const searchFromQueryLink = window.history.state?.usr;

    // detect when user applies filters
    useEffect(() => {
        // when the user leaves the page, we need to remove the query params from the state
        return () => {
            replaceLocationState();
        };
    }, []);

    // LISTENERS
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        getDocuments(true);
    }, [columnFilters, sortParams]);

    useEffect(() => {
        if (!tagsLoaded) return;

        if (searchFromQueryLink) return handleQueryLink(searchFromQueryLink);

        getDocuments(true, false);
    }, [tags]);

    useEffect(() => {
        if (!isFirstRender.current && !selectedProject?.id) {
            resetMainStates();
        }
    }, [selectedProject?.id]);

    // detect when infinite scrolling hook changes the page
    useEffect(() => {
        if (pageNumber === 0) return;

        getMoreResults();
    }, [pageNumber]);

    const handleQueryLink = (state = {}) => {
        // simulate applying a column filter
        const { dataFilter } = state;

        handlePreviewMode();

        if (dataFilter?.ids?.length) {
            // ids are a subset of insight ids to be displayed
            onApplyFilter({
                column: 'ids',
                values: [
                    {
                        label: 'ids',
                        value: dataFilter.ids.join('&'),
                        __id: 'ids',
                    },
                ],
                isDynamicColumnFilter: false,
            });
            return;
        }
    };

    const getTableColumns = async () => {
        setLoading(true);

        const [error, response] =
            await documentServices.getDocumentDynamicProperties({
                project_ids: selectedProject?.id,
            });

        setLoading(false);

        if (error) return;

        // to easily access the columns by id
        const mappedToObject = response.data.reduce(
            (
                acc: {
                    [key: string]: CGDocumentDynamicPropertyInterfaceDetailed;
                },
                curr: CGDocumentDynamicPropertyInterfaceDetailed
            ) => {
                acc[curr.id] = curr;
                return acc;
            },
            {}
        );

        setDynamicColumns(mappedToObject);
    };

    const getDocuments = async (isFiltered: boolean, resetSelection = true) => {
        if (resetSelection) onSelectItem('');

        getColumnValues();
        setLoading(true);

        const filtersPayload = isFiltered
            ? extractFilters(
                  filterParams,
                  Object.values(dynamicColumns),
                  columnValues
              )
            : {};

        const options = {
            ...filtersPayload,
            page: 0,
            page_size: pageSize,
            project_ids: selectedProject?.id,
            ...buildSortingParameters(sortParams),
        };

        const [error, response] =
            await documentServices.getDocumentInsights(options);

        setLoading(false);

        if (error) return;

        const { data, paging } = response;

        setDocuments(data);
        setTotalItems(paging.total);

        if (pageNumber !== 0) onPageChange(0);

        setLoadingScreenshots(true);

        const modifiedData =
            await documentServices.appendImagesToDocumentInsights(data);

        setDocuments(modifiedData);
        setLoadingScreenshots(false);
    };

    const getMoreResults = async () => {
        setLoading(true);

        const filtersPayload = extractFilters(
            filterParams,
            Object.values(dynamicColumns),
            columnValues
        );

        const options = {
            page: pageNumber,
            page_size: pageSize,
            ...filtersPayload,
            project_ids: selectedProject?.id,
            ...buildSortingParameters(sortParams),
        };

        const [error, response] =
            await documentServices.getDocumentInsights(options);

        setLoading(false);

        if (error) return;

        const { data } = response;

        setLoadingScreenshots(true);

        const modifiedData =
            await documentServices.appendImagesToDocumentInsights(data);

        setLoadingScreenshots(false);
        setDocuments([...documents, ...modifiedData]);
    };

    const getColumnValues = async (queryFilters = {}) => {
        const filtersPayload = extractFilters(
            filterParams,
            Object.values(dynamicColumns),
            columnValues
        );

        const [error, response] =
            await documentServices.getDocumentInsightsColumnValues({
                // if filters are applied by a query link, then set all column values
                ...filtersPayload,
                ...queryFilters,
                project_ids: selectedProject?.id,
            });

        if (!error) {
            handleColumnValues(parseColumnValues(response.data));
        }
    };

    const onCopyLink = () => {
        // if items param exists, then the user is copying a link to a selection of insights
        const filters = selectedItems.length ? { ids: selectedItems } : {};

        generateDocumentInsightFilteredLink(
            selectedWorkspace?.id,
            selectedProject?.id,
            filters,
            true
        );

        toast.success(intl.formatMessage({ id: 'link_copied_to_clipboard' }));
    };

    const resetMainStates = () => {
        documents.length && setDocuments([]);
        selectedItems.length && setSelectedItems([]);
        Object.keys(dynamicColumns).length && setDynamicColumns({});
    };

    const onDownload = async (all: boolean | undefined) => {
        analytics.trackGenericEvent(EVENT_NAMES_ENUM.EXPORT_EXCEL);

        let documentsToDownload;

        if (all || selectedAll) {
            setLoading(true);

            const filtersPayload = extractFilters(
                filterParams,
                Object.values(dynamicColumns),
                columnValues
            );

            const options = {
                ...filtersPayload,
                project_ids: selectedProject?.id,
                all: true,
                ...buildSortingParameters(sortParams),
            };

            const [error, response] =
                await documentServices.getDocumentInsights(options);

            if (error) {
                setLoading(false);
                return;
            }

            const modifiedData =
                await documentServices.appendImagesToDocumentInsights(
                    response.data
                );

            setLoading(false);

            documentsToDownload = modifiedData;
        } else {
            documentsToDownload = documents.filter((i) =>
                selectedItems.includes(i.id)
            );
        }

        // set column name based on its type
        orderedColumns.forEach((col) => {
            if (col.type === DOCUMENT_TABLE_POSITION_TYPE.DYNAMIC_PROPERTY) {
                col.name =
                    dynamicColumns[col.document_dynamic_property_id]?.name;
                col.dynamicType =
                    dynamicColumns[col.document_dynamic_property_id]?.type;
            }

            if (col.type === DOCUMENT_TABLE_POSITION_TYPE.TAG) {
                col.name = intl.formatMessage({ id: 'tags' });
            }

            if (col.type === DOCUMENT_TABLE_POSITION_TYPE.SOURCE) {
                col.name = intl.formatMessage({ id: 'source' });
            }

            if (col.type === DOCUMENT_TABLE_POSITION_TYPE.TOPIC) {
                col.name = objectMappedTopics[col.topic_id]?.name;
            }
        });

        const { buildDocumentsTableViewExcel } = await documentExcelBuilder();

        const excel = await buildDocumentsTableViewExcel({
            results: documentsToDownload,
            columns: orderedColumns,
            projectId: selectedProject?.id as string,
            workspaceId: selectedWorkspace?.id as string,
            groupedTags,
        });

        if (excel) {
            // dynamic import
            const { downloadBuffer } = await bufferDownloadTool();
            downloadBuffer(excel.buffer, excel.fileName);
        }
    };

    const handlePreviewMode = () => {
        resetAllFilters();
        replaceLocationState();
    };

    const {
        columnsToRender,
        onAddNewColumn,
        handleColumnsOrder,
        orderedColumns,
    } = useDynamicDocumentInsightsColumn({
        results: documents,
        filtering: {
            columnFilters,
            columnValues,
            sortParams,
            resetFilterColumn,
            onApplyFilter,
        },
        loadingScreenshots,
        loading,
        canEdit,
        dynamicColumns,
        setLoading,
        handleUpdateCell: setDocuments,
        onUpdateCellCallback: getColumnValues,
        handleViewImage: handleImage,
        getTableColumns,
        handleDynamicColumns: setDynamicColumns,
    });

    const tableHeight = useFlexTableHeight({
        containerClassName: 'cg-insights',
        offset: 75 + (selectedItems.length ? 45 : 0),
    });

    const documentIds = useMemo(
        () => getRawColumnValues(columnValues, 'name'),
        [columnValues]
    );

    return (
        <>
            <InsightsSubheader
                intl={intl}
                view={InsightsResultsView.DOCUMENTS}
                onCopyLink={onCopyLink}
                onDownload={onDownload}
                previewMode={previewMode}
                handleView={handleView}
                handlePreviewMode={handlePreviewMode}
                loading={loading}
                showActions={!!selectedProject && selectedItems.length === 0}
            />

            <DocumentInsightsTable
                loading={loading}
                setLoading={setLoading}
                documents={documents}
                columns={columnsToRender}
                tableHeight={tableHeight}
                onScrollTouchesBottom={() =>
                    !loading && onPageChange(pageNumber + 1)
                }
                paging={{
                    onSelectItem,
                    setSelectedItems,
                    selectedItems,
                    setSelectedAll,
                    selectedAll,
                    totalPages,
                    pageNumber,
                }}
                onAddNewColumn={(c: NewDynamicColumnInterface) => {
                    onAddNewColumn(c);
                    getColumnValues();
                }}
                reorderableColumns={canEdit}
                handleColumnsOrder={handleColumnsOrder}
                onUpdateCallback={() => getDocuments(true)}
                onDownloadFiles={onDownload}
                onCopyLink={onCopyLink}
                documentIds={documentIds}
            />
        </>
    );
};

export default DocumentsResults;
