import { InsightsResultsView } from '~/interfaces/contexts';
import { V1_LINK_ROUTES } from '../components/Router/constants';
import { getQueryLink } from './getQueryLink';

export const generateInsightImageLink = (
    workspaceId: string = '',
    projectId: string = '',
    insightImage = '',
    copyToClipboard = false
) => {
    const params = {
        workspace: workspaceId.length ? workspaceId : undefined,
        project: projectId.length ? projectId : undefined,
        insightImage,
    };
    return getQueryLink(params, V1_LINK_ROUTES.insights, copyToClipboard);
};

export const generateDocumentInsightFilteredLink = (
    workspaceId = [],
    projectId = [],
    columnFilters = {},
    copyToClipboard = true
) => {
    const params = {
        dataFilter: Object.keys(columnFilters).length
            ? columnFilters
            : undefined,
        workspace: workspaceId.length ? workspaceId : undefined,
        project: projectId.length ? projectId : undefined,
        view: InsightsResultsView.DOCUMENTS,
    };
    return getQueryLink(
        params,
        V1_LINK_ROUTES.document_insights,
        copyToClipboard
    );
};

export const generateInsightTopicFilteredLink = (
    workspaceId: string,
    projectId: string,
    columnFilters = {},
    copyToClipboard = true
) => {
    // remove undefined values from columnFilters in order to avoid setting them in the URL

    const params = {
        dataFilter: Object.keys(columnFilters).length
            ? columnFilters
            : undefined,
        workspace: workspaceId.length ? workspaceId : undefined,
        project: projectId.length ? projectId : undefined,
    };
    return getQueryLink(params, V1_LINK_ROUTES.insights, copyToClipboard);
};

export const generateReportLink = (
    workspaceId: string,
    projectId: string,
    reportId: string,
    copyToClipboard = true
) => {
    const params = {
        workspace: workspaceId,
        project: projectId,
        report: reportId,
    };

    return getQueryLink(params, V1_LINK_ROUTES.reports, copyToClipboard);
};

export const generatePDFLink = (
    documentId: string,
    workspaceId: string,
    projectId: string,
    copyToClipboard = true
) => {
    const params = {
        documentId,
        workspace: workspaceId,
        project: projectId,
    };
    return getQueryLink(
        params,
        V1_LINK_ROUTES.insights_smartviewer,
        copyToClipboard
    );
};

export const generatePDFPageHighlightedLink = (
    commentId: string,
    workspaceId: string,
    projectId: string,
    copyToClipboard = true
) => {
    const params = {
        commentId: commentId,
        workspace: workspaceId,
        project: projectId,
    };

    return getQueryLink(
        params,
        V1_LINK_ROUTES.insights_smartviewer_page_highlight,
        copyToClipboard
    );
};
