import { AccumulatedColumnValues } from '~/interfaces/columnValues/ColumnFilterContext.interface';

const getRawColumnValues = (
    columnValues: AccumulatedColumnValues,
    columnName: string
): string[] => {
    const colValues = columnValues[columnName];
    if (!colValues) return [];

    // values originally are an array of strings that were joined by &&, so we need to split them
    const rawValues = colValues.map((joinedValues) =>
        joinedValues.value.split('&').flat()
    );

    return rawValues.flat();
};

export default getRawColumnValues;
