import { TagsContext } from '~/context/tags';
import { useEntityContext } from '~/hooks';
import { ColumnValueOption } from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { CGDocumentInterfaceDetailed } from '~/interfaces/entities';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';

interface UseCommonDocumentsTagsProps {
    documents: CGDocumentInterfaceDetailed[];
}

interface UseCommonDocumentsTagsResults {
    options: ColumnValueOption[];
    ids: string[];
}

const useCommonDocumentsTags = ({
    documents,
}: UseCommonDocumentsTagsProps): UseCommonDocumentsTagsResults => {
    const { tags, tagOptions } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    if (!tags.length || !documents.length) {
        return {
            options: [],
            ids: [],
        };
    }

    const documentTags = documents.map(
        (doc) => new Set(doc.tags.map((t) => t.id))
    );

    let commonTags = Array.from(documentTags[0]);

    for (const tags of documentTags) {
        commonTags = commonTags.filter((tag) => tags.has(tag));
    }

    const options = tagOptions.filter((t) => commonTags.includes(t.value));

    return {
        options,
        ids: commonTags,
    };
};

export default useCommonDocumentsTags;
