import { MultiSelectCellInterface } from '~/components/Tables/MultiSelectCell';
import { CGDocumentDynamicValueInterfaceDetailed } from '../interfaces/entities/CGDocumentDynamicValue.interface';

export const normalizeDocumentCell = (
    cell: CGDocumentDynamicValueInterfaceDetailed | undefined
): MultiSelectCellInterface => {
    if (!cell) {
        return {
            id: '',
            rowId: '',
            columnId: '',
            options: [],
        };
    }

    return {
        id: cell.id,
        rowId: cell.document_id,
        columnId: cell.document_dynamic_property_id,
        options: cell.document_select_options,
    };
};
