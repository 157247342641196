import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { Download, Link, Trash2 } from 'react-feather';
import {
    Button,
    DeleteConfirmationDialog,
    IconButton,
} from '~/components/UIElements';
import RestrictedUI from '~/components/RestrictedUI';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { useFloatMenu } from '~/hooks';
import { documentServices } from '~/services';
import { getErrorCode } from '~/utils/getErrorCode';
import { CGDocumentInterfaceDetailed } from '~/interfaces/entities';
import { PagingValues } from '~/hooks/useTable';
import styles from './DocumentsTableHeader.module.scss';
import TagDocumentsButtonMenu from '~/modules/Documents/components/DocumentsTable/TagDocumentsButtonMenu';

interface DocumentsTableHeaderProps {
    loading: boolean;
    documents: CGDocumentInterfaceDetailed[];
    paging: Pick<PagingValues, 'selectedItems' | 'onSelectItem'>;
    documentIds: string[];
    onUpdateCallback: () => void;
    handleLoading: (loading: boolean) => void;
    resetSelectedItems: () => void;
    onDownloadFiles?: () => void;
    onCopyLink?: () => void;
}

const DocumentsTableHeader = ({
    loading,
    documents,
    paging,
    documentIds,
    onDownloadFiles,
    onUpdateCallback,
    onCopyLink,
    handleLoading,
    resetSelectedItems,
}: DocumentsTableHeaderProps) => {
    const totalItems = documentIds.length;

    const intl = useIntl();

    const { selectedItems, onSelectItem } = paging;

    const deleteMenu = useFloatMenu();

    const selectedAllVisible =
        selectedItems.length !== totalItems &&
        selectedItems.length === documents.length;

    const onRemoveDocument = async () => {
        handleLoading(true);

        const [error, response] =
            await documentServices.deleteFiles(selectedItems);

        handleLoading(false);

        deleteMenu.handleMenu();

        if (error) {
            toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );
            return;
        }

        const isBatch = selectedItems.length > 1;

        toast.success(
            intl.formatMessage({
                id: (isBatch ? 'files' : 'file') + '_removed_successfully',
            })
        );
        onUpdateCallback();
    };

    if (!selectedItems.length) return null;

    const deleteItemsLabel =
        intl.formatMessage({
            id: `delete_${selectedItems.length > 1 ? 'files' : 'file'}`,
        }) + '?';

    const visibleSelectedDocuments = documents.filter((doc) =>
        selectedItems.includes(doc.id)
    );

    return (
        <div
            className={
                styles.cgDocumentsTableHeader +
                ' animate__animated animate__fadeIn'
            }
        >
            {deleteMenu.showMenu && (
                <DeleteConfirmationDialog
                    handleClose={deleteMenu.handleMenu}
                    callback={onRemoveDocument}
                    buttonText={intl.formatMessage({ id: 'delete' })}
                    text={intl.formatMessage({
                        id: 'this_action_cannot_be_undone',
                    })}
                    title={deleteItemsLabel}
                    loading={loading}
                />
            )}

            <div className={styles.cgDocumentsTableHeader__container}>
                {selectedItems.length > 0 && (
                    <span className={styles.cgDocumentsTableHeader__selection}>
                        {selectedItems.length}{' '}
                        {intl.formatMessage({ id: 'selected' })}
                    </span>
                )}

                {selectedAllVisible && (
                    <Button
                        variant="secondary"
                        onClick={() => onSelectItem(documentIds)}
                        disabled={loading}
                    >
                        {intl.formatMessage({ id: 'select_all' })} ({totalItems}
                        )
                    </Button>
                )}

                <Button
                    variant="secondary"
                    onClick={resetSelectedItems}
                    disabled={loading}
                >
                    {intl.formatMessage({ id: 'deselect' })}
                </Button>

                {onCopyLink && (
                    <Button
                        variant="secondary"
                        iconBefore={Link}
                        onClick={onCopyLink}
                    >
                        <span>
                            {intl.formatMessage({
                                id: 'copy_link_to_selection',
                            })}
                        </span>
                    </Button>
                )}

                <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.DOCUMENTS}>
                    <TagDocumentsButtonMenu
                        visibleSelectedDocuments={visibleSelectedDocuments}
                        selectedDocumentIds={selectedItems}
                    />
                </RestrictedUI>

                {onDownloadFiles && (
                    <Button
                        variant="secondary"
                        onClick={onDownloadFiles}
                        disabled={loading}
                        iconBefore={Download}
                    >
                        {intl.formatMessage({ id: 'download' })} .xlsx
                    </Button>
                )}

                <RestrictedUI to={PERMISSIONS.EDIT} entity={ENTITIES.DOCUMENTS}>
                    <IconButton
                        icon={Trash2}
                        onClick={deleteMenu.handleMenu}
                        variant="secondary"
                        disabled={loading}
                    />
                </RestrictedUI>
            </div>
        </div>
    );
};

export default DocumentsTableHeader;
