export const fixedColumnsMetadata = {
    source: {
        label: 'Source',
        key: 'name',
        i8n: 'source',
    },
    tag: {
        label: 'Tags',
        key: 'tag_name',
        i8n: 'tags',
    },
} as const;

export const mappedKeysToRows = {
    [fixedColumnsMetadata.tag.key]: 'document_tags',
    [fixedColumnsMetadata.source.key]: 'document',
} as const;

export enum DefaultDocumentColumns {
    SOURCE = 'source',
    TAG = 'tag',
}

const FIXED_COLUMN_NAMES = Object.keys(fixedColumnsMetadata);
export default FIXED_COLUMN_NAMES;
