import { PRIVATE_ROUTES } from './paths';

const v1_prefix = '/links/v1';

export const V1_LINK_ROUTES = {
    // wrapper : params
    // { search_type: 'macro', blocks, filters }
    search: v1_prefix + PRIVATE_ROUTES.search,

    // wrapper : params
    // { workspace, project, topic, dataFilter? }
    insights: v1_prefix + PRIVATE_ROUTES.collection_topics,

    // wrapper: smartViewer
    // { documentId, fileId }
    insights_smartviewer: v1_prefix + PRIVATE_ROUTES.collection_topics,

    // wrapper: smartViewer
    // { documentId, fileId, page, text }
    insights_smartviewer_page: v1_prefix + PRIVATE_ROUTES.collection_topics,

    // wrapper: smartViewer
    // { id }
    insights_smartviewer_page_highlight:
        v1_prefix + PRIVATE_ROUTES.collection_topics,

    // wrapper: params
    document_insights: v1_prefix + '/document_insights',

    // wrapper : params
    collection_tags: v1_prefix + PRIVATE_ROUTES.collection_tags, // this route actually doesn't exist but it's needed to redirect existing query links

    // wrapper : params
    // { workspace, project, report }
    reports: v1_prefix + PRIVATE_ROUTES.reports,
};
