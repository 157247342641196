import { fixedColumnsMetadata } from '~/constants/documentInsightTableColumns';
import { mapDynamicFilters } from '~/helpers/insightsDataHandlers';
import { AccumulatedColumnValues } from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { CGDocumentDynamicPropertyInterfaceDetailed } from '~/interfaces/entities';

export const matchFilterKeys = (filters: AccumulatedColumnValues) => {
    const transformedIds = [];
    if (filters.ids) {
        for (const filter of filters.ids) {
            transformedIds.push(...filter.value.split('&'));
        }
    }
    return {
        ids: transformedIds.length ? [...new Set(transformedIds)] : undefined,
        tag_ids: filters[fixedColumnsMetadata.tag.key]?.map((d) => d.value),
    };
};

export const extractFilters = (
    filterParameters: AccumulatedColumnValues,
    tableColumns: CGDocumentDynamicPropertyInterfaceDetailed[],
    columnValues: AccumulatedColumnValues
) => {
    const filtersPayload = matchFilterKeys(filterParameters);

    const dynamicallyFilteredIds = mapDynamicFilters(
        filterParameters,
        tableColumns,
        columnValues
    );

    filtersPayload.ids ??= [];
    filtersPayload.ids.push(...dynamicallyFilteredIds);

    return filtersPayload;
};
