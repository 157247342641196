import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import FIXED_COLUMN_NAMES, {
    DefaultDocumentColumns,
    fixedColumnsMetadata,
} from '~/constants/documentInsightTableColumns';
import { defaultColumnsId } from '~/constants/insightTableColumns';
import { TopicsContext } from '~/context/topics';
import {
    CGDocumentDynamicPropertyInterfaceDetailed,
    CGDocumentDynamicValueInterfaceDetailed,
    CGDocumentInsightInterface,
    CGDocumentTablePositionInterface,
    InsightInterfaceDetailed,
} from '~/interfaces/entities';
import { documentServices } from '~/services';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';
import { DOCUMENT_TABLE_POSITION_TYPE } from '~/constants/DocumentTablePositionTypes.enum';
import {
    CustomColumnHeader,
    EditableCell,
    MultiSelectCell,
    QuoteCell,
    TagCell,
} from '~/components/Tables';
import { NewDynamicColumnInterface } from '~/components/Tables/AddColumnHeader';
import toast from 'react-hot-toast';
import { getErrorCode } from '~/utils/getErrorCode';
import { SwitchInput, TagsColumnFilter } from '~/components/UIElements';
import { DOCUMENT_DYNAMIC_PROPERTY_TYPE } from '~/constants/DocumentDynamicPropertyTypes.enum';
import { normalizeDocumentCell } from '~/helpers/normalizeDynamicDocumentCell';
import { CellItemsPayloadInterface } from '~/components/Tables/MultiSelectCell';
import { updateColumnsOrder } from '~/utils/orderColumnsFunctions';
import { useEntityContext } from '~/hooks';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TableColumnModelInterface } from '~/components/Tables/BaseTable';
import {
    ImageInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { TagsContext } from '~/context/tags';
import { useColumnsFilterContextInterface } from '~/hooks/useColumnsFilterContext';
import { ProjectContext } from '~/context/project';
import useDocumentViewer from '~/modules/Documents/hooks/useDocumentViewer';
import useDocumentViewerStore from '~/modules/Documents/stores/documentViewerStore';

interface TableModelInterface {
    [key: string]: TableColumnModelInterface;
}

interface CellDataInterface {
    data: CGDocumentDynamicValueInterfaceDetailed;
    value: null | string | number | boolean;
}

interface UpdateCellDTO {
    rowData: CGDocumentInsightInterface;
    colId: string;
    cellType: DYNAMIC_PROPERTY_TYPES;
    newValue: string | number | boolean | null;
}

interface PropsInterface {
    results: CGDocumentInsightInterface[];
    filtering: Pick<
        useColumnsFilterContextInterface,
        | 'columnFilters'
        | 'columnValues'
        | 'sortParams'
        | 'resetFilterColumn'
        | 'onApplyFilter'
    >;
    loadingScreenshots: boolean;
    loading: boolean;
    canEdit: boolean;
    dynamicColumns: {
        [key: string]: CGDocumentDynamicPropertyInterfaceDetailed;
    };
    onUpdateCellCallback: () => void;
    handleViewImage: (payload: ImageInterface | null) => void;
    setLoading: (loading: boolean) => void;
    handleUpdateCell: (newDocs: CGDocumentInsightInterface[]) => void;
    getTableColumns: () => void;
    handleDynamicColumns: (newCols: {
        [key: string]: CGDocumentDynamicPropertyInterfaceDetailed;
    }) => void;
}

const useDynamicDocumentInsightsColumn = ({
    canEdit,
    results = [],
    filtering: {
        columnFilters,
        columnValues = {},
        sortParams,
        resetFilterColumn,
        onApplyFilter,
    },
    loadingScreenshots,
    loading,
    dynamicColumns,
    setLoading,
    handleUpdateCell,
    onUpdateCellCallback,
    handleViewImage,
    getTableColumns,
    handleDynamicColumns,
}: PropsInterface) => {
    const intl = useIntl();

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const selectedProjectId = selectedProject?.id;

    // the building of the columns is managed by useMemo and useCallbacks hooks which take some miliseconds to update
    const delayHandleLoading = () => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const { handleViewDocument } = useDocumentViewer();

    const documentMetadata = useDocumentViewerStore(
        (state) => state.documentMetadata
    );

    const { objectMappedTopics, isFirstRender: isTopicsFirstRender } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { folderTagsColors } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const [tablePositions, setTablePositions] = useState<
        CGDocumentTablePositionInterface[]
    >([]);

    useEffect(() => {
        if (!selectedProjectId) {
            return;
        }

        getTableColumns();
    }, [selectedProjectId]);

    useEffect(() => {
        if (!selectedProjectId || isTopicsFirstRender) return;
        getTablePositions();
    }, [objectMappedTopics]);

    const getTablePositions = async () => {
        const [error, response] =
            await documentServices.getDocumentTablePositions({
                all: true,
                project_ids: selectedProjectId,
            });

        if (error) return;

        setTablePositions(response.data);
    };

    const model: TableModelInterface = useMemo(
        () => ({
            [DefaultDocumentColumns.SOURCE]: {
                cell: (row) => (
                    <div
                        title={row.name}
                        className="capture-cell"
                        onClick={() =>
                            handleViewDocument({
                                id: row.id,
                            })
                        }
                    >
                        {row.name}
                    </div>
                ),
                id: '99',
                field: fixedColumnsMetadata.source.key,
                header: intl.formatMessage({
                    id: fixedColumnsMetadata.source.i8n,
                }),
                style: { minWidth: '300px' },
            },
            [DefaultDocumentColumns.TAG]: {
                header: () => (
                    <TagsColumnFilter
                        headerName={fixedColumnsMetadata.tag.i8n}
                        intl={intl}
                        columnName={fixedColumnsMetadata.tag.key}
                        resetFilterColumn={resetFilterColumn}
                        columnFilters={columnFilters}
                        onApplyFilter={onApplyFilter}
                        columnValues={
                            columnValues[fixedColumnsMetadata.tag.key]
                        }
                        sortParams={sortParams}
                    />
                ),
                cell: (row) => (
                    <TagCell
                        documentId={row.id}
                        selectedTags={row.tags}
                        disabled={!canEdit}
                        onOpenFile={() =>
                            handleViewDocument({
                                id: row.id,
                            })
                        }
                        folderTagsColors={folderTagsColors}
                        renderTree
                    />
                ),
                id: '98',
                field: fixedColumnsMetadata.tag.key,
                style: { minWidth: '300px' },
            },
        }),
        [
            columnFilters,
            columnValues,
            folderTagsColors,
            documentMetadata,
            sortParams,
            canEdit,
        ]
    );

    // order the columns based on the order property
    const orderedColumns = useMemo(() => {
        if (!tablePositions.length) return [];

        const orderedColumns = tablePositions.sort(
            (a, b) => a.position - b.position
        );

        // just to log: check sequence complete
        const sequenceComplete = orderedColumns.every(
            (col, idx) => col.position === idx
        );
        if (!sequenceComplete) console.error('columns sequence is incorrect');

        return orderedColumns;
    }, [tablePositions, dynamicColumns]);

    const onOpenInsight = (insight: InsightInterfaceDetailed) => {
        const payload = {
            initialPage: insight.page_number,
            id: insight.document_id,
        };

        handleViewDocument(payload);
    };

    const onUpdateCell = async (cellInfo: UpdateCellDTO): Promise<void> => {
        const { rowData, colId, cellType, newValue } = cellInfo;

        if (newValue === undefined || !rowData) return;

        const cell = getCell(rowData, colId);

        if (!cell && newValue === null) return;

        // if value didn't change, do nothing
        if (cell?.value === newValue) return;

        // store the changes in DB
        const valuePayload = {
            value_number:
                cellType === DYNAMIC_PROPERTY_TYPES.NUMBER
                    ? (newValue as number)
                    : null,
            value_text:
                cellType === DYNAMIC_PROPERTY_TYPES.TEXT
                    ? (newValue as string)
                    : null,
            value_boolean:
                cellType === DYNAMIC_PROPERTY_TYPES.BOOLEAN
                    ? (newValue as boolean)
                    : null,
        };

        // check if the cell already exist, if so, update it, otherwise create a new one
        const [error, response] = cell
            ? await documentServices.updateDocumentDynamicValue(
                  cell?.data.id,
                  valuePayload
              )
            : await documentServices.createDocumentDynamicValue({
                  document_id: rowData.id,
                  document_dynamic_property_id: colId,
                  ...valuePayload,
              });

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        const { data } = response;

        // update the internal state (without requesting all the data again and lose the scroll position)
        if (cell) {
            rowData.document_dynamic_values =
                rowData.document_dynamic_values.map((c) => {
                    if (c.document_dynamic_property_id === colId) {
                        c = data;
                    }
                    return c;
                });
        } else {
            rowData.document_dynamic_values.push(data);
        }

        // update results array
        const newResults = results.map((r) => {
            if (r.id === rowData.id) {
                return rowData;
            }
            return r;
        });

        handleUpdateCell(newResults);
        onUpdateCellCallback();
    };

    const onRemoveColumn = async (columnId: string) => {
        setLoading(true);

        const [error, response] =
            await documentServices.deleteDocumentDynamicProperty(columnId);

        delayHandleLoading();
        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        toast.success(
            intl.formatMessage({ id: 'column_removed_successfully' })
        );

        getTablePositions();
        getTableColumns();
    };

    const onUpdateColumnName = async ({
        name,
        id,
    }: {
        name: string;
        id: string;
    }) => {
        setLoading(true);

        const [error] = await documentServices.updateDocumentDynamicProperty(
            id,
            {
                name,
            }
        );

        delayHandleLoading();

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        const colsCopy = { ...dynamicColumns };
        colsCopy[id].name = name;

        handleDynamicColumns(colsCopy);
    };

    const getCell = useCallback(
        (
            rowData: CGDocumentInsightInterface,
            columnId: string
        ): CellDataInterface | null => {
            const cell = rowData.document_dynamic_values.find(
                (c) => c.document_dynamic_property_id === columnId
            );

            if (!cell) return null;

            // the value should be the first one that is not null
            const value = [
                cell.value_text,
                cell.value_number,
                cell.value_boolean,
            ].find((v) => v !== null);

            return {
                data: cell,
                // if all values are null, return null
                value: value === undefined ? null : value,
            };
        },
        []
    );

    // for multi select and single select cells
    const onAddCellItems = async (
        payload: CellItemsPayloadInterface,
        callback: () => void,
        documentId: string,
        columnId: string
    ) => {
        let cellId: string = payload.cellId;

        setLoading(true);

        // if the cell needs to be created (it doesn't exist yet)
        if (payload.createCell) {
            const [error, response] =
                await documentServices.createDocumentDynamicValue({
                    document_id: documentId,
                    document_dynamic_property_id: columnId,
                });

            if (error) {
                toast.error(
                    intl.formatMessage({
                        id: getErrorCode(response.error_code),
                    })
                );
                delayHandleLoading();

                return;
            }

            cellId = response.data.id;
        }

        const itemsToAdd = payload.itemsToAdd ?? [];

        if (payload.itemsToCreate?.length) {
            const itemPromises = payload.itemsToCreate.map((t) =>
                documentServices.createDocumentDynamicOptions({
                    document_dynamic_property_id: columnId,
                    name: t,
                })
            );
            const response = await Promise.all(itemPromises);
            if (response?.length) {
                response.forEach(
                    ([error, response]) =>
                        !error && itemsToAdd.push(response.data.id)
                );
            }
        }

        const updatedPayload = {
            add_select_option_ids: itemsToAdd,
            remove_select_option_ids: payload.itemsToRemove || [],
        };

        const [error] = await documentServices.updateDynamicValueOptions(
            cellId,
            updatedPayload
        );

        delayHandleLoading();

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(intl.formatMessage({ id: 'items_updated_successfully' }));

        callback();
        getTableColumns();
        onUpdateTags();
    };

    const onUpdateCellItem = async (itemId: string, newLabel: string) => {
        setLoading(true);

        const [error, response] =
            await documentServices.updateDocumentDynamicOptions(itemId, {
                name: newLabel,
            });

        delayHandleLoading();

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        toast.success(intl.formatMessage({ id: 'items_updated_successfully' }));
        getTableColumns();
        onUpdateTags();
    };

    const onDeleteCellItem = async (itemId: string) => {
        setLoading(true);

        const [error, response] =
            await documentServices.deleteDocumentDynamicOptions(itemId);

        delayHandleLoading();

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        toast.success(intl.formatMessage({ id: 'items_updated_successfully' }));
        getTableColumns();
    };

    const getCustomCell = (
        col: CGDocumentTablePositionInterface
    ): TableModelInterface => {
        const base = {
            field: col.id,
            id: col.id,
            style: { minWidth: '180px' },
        };

        const rest = {};

        // the entity that stores the position of the columns contemplates only four types of columns. One of them is the dynamic_property.
        // inside the dynamic_property, we have the rest types of columns (text, number, boolean, select, multi_select)

        if (col.type === DOCUMENT_TABLE_POSITION_TYPE.TOPIC) {
            const topic = col.topic_id
                ? objectMappedTopics[col.topic_id]
                : null;

            rest.cell = (row: CGDocumentInsightInterface) => {
                const insight = row.latest_comments.find(
                    (comment) => comment.topic_id === col.topic_id
                );

                return insight ? (
                    <QuoteCell
                        cell={insight}
                        loading={loadingScreenshots}
                        onClick={(e) => {
                            if (!window.getSelection()?.toString())
                                onOpenInsight(insight); // allow text selection before opening the file
                            e.stopPropagation();
                        }}
                        onOpenImage={(img) => handleViewImage({ blob: img })}
                    />
                ) : (
                    ''
                );
            };

            rest.header = () => (
                <span title={topic?.name || ''}>{topic?.name || ''}</span>
            );

            rest.style = {
                ...rest.style,
                maxWidth: '300px',
            };
        }

        if (col.type === DOCUMENT_TABLE_POSITION_TYPE.TAG) {
            base.header = 'Tags';
            base.hidden = true;
        }

        const dynamicColumn:
            | CGDocumentDynamicPropertyInterfaceDetailed
            | undefined =
            col.document_dynamic_property_id &&
            dynamicColumns[col.document_dynamic_property_id];

        if (dynamicColumn) {
            const colType = dynamicColumn.type;

            base.header = (
                <CustomColumnHeader
                    intl={intl}
                    column={dynamicColumn}
                    onRemove={() => onRemoveColumn(dynamicColumn.id)}
                    onEdit={onUpdateColumnName}
                    canEdit={canEdit}
                    //
                    resetFilterColumn={resetFilterColumn}
                    columnFilters={columnFilters}
                    onApplyFilter={onApplyFilter}
                    columnValues={columnValues[dynamicColumn.id] || []}
                    sortParams={sortParams}
                    sortFieldName={dynamicColumn.id}
                />
            );

            if (
                colType === DOCUMENT_DYNAMIC_PROPERTY_TYPE.TEXT ||
                colType === DOCUMENT_DYNAMIC_PROPERTY_TYPE.NUMBER
            ) {
                base.cell = (rowData: CGDocumentInsightInterface) => (
                    <EditableCell
                        value={getCell(rowData, dynamicColumn.id)?.value}
                        type={colType}
                        allowEdit={false}
                    />
                );

                base.editor = ({ rowIndex }: { rowIndex: number }) => {
                    const row = results[rowIndex];
                    const inputValue = getCell(row, dynamicColumn.id)?.value;
                    return (
                        <EditableCell
                            value={inputValue}
                            type={colType}
                            allowEdit={canEdit}
                            onCompleteEdit={(newValue: string | number) =>
                                onUpdateCell({
                                    rowData: row,
                                    colId: dynamicColumn.id,
                                    cellType: colType,
                                    newValue: newValue === '' ? null : newValue,
                                })
                            }
                        />
                    );
                };
            }

            if (colType === DOCUMENT_DYNAMIC_PROPERTY_TYPE.BOOLEAN) {
                base.cell = (rowData: CGDocumentInsightInterface) => (
                    <SwitchInput
                        disabled={!canEdit}
                        isOn={Boolean(
                            getCell(rowData, dynamicColumn.id)?.value
                        )}
                        id={dynamicColumn.id + rowData.id}
                        handleToggle={(e) => {
                            if (!canEdit) return;
                            onUpdateCell({
                                rowData,
                                colId: dynamicColumn.id,
                                cellType: colType,
                                newValue: e.target.checked,
                            });
                        }}
                    />
                );
                base.align = 'center';
            }

            if (
                colType === DOCUMENT_DYNAMIC_PROPERTY_TYPE.MULTI_SELECT ||
                colType === DOCUMENT_DYNAMIC_PROPERTY_TYPE.SELECT
            ) {
                base.cell = (rowData: CGDocumentInsightInterface) => (
                    <MultiSelectCell
                        intl={intl}
                        columnItems={dynamicColumn.document_select_options}
                        onApply={(items, callback) =>
                            onAddCellItems(
                                items,
                                callback,
                                rowData.id,
                                dynamicColumn.id
                            )
                        }
                        onUpdateItems={onUpdateCellItem}
                        onDeleteCellItems={onDeleteCellItem}
                        disabled={!canEdit}
                        cell={normalizeDocumentCell(
                            getCell(rowData, dynamicColumn.id)?.data
                        )}
                        loading={loading}
                        singleItem={
                            colType === DOCUMENT_DYNAMIC_PROPERTY_TYPE.SELECT
                        }
                    />
                );
            }
        }

        return {
            ...base,
            ...rest,
        };
    };

    const handleColumnsOrder = async (
        columnToMove: string,
        newPosition: number
    ) => {
        const { newColumnPosition } = updateColumnsOrder(
            orderedColumns,
            columnToMove,
            newPosition
        );

        const [error, response] = await documentServices.updateTablePositions(
            columnToMove,
            newColumnPosition
        );

        if (error || !response.data) return;

        getTablePositions();
    };

    const columnsToRender = useMemo(() => {
        if (!selectedProjectId) return [];
        if (!orderedColumns.length) return [];

        const cols = orderedColumns.map((col) => {
            // check if the column is fixed (default one) or not
            const isFixed = FIXED_COLUMN_NAMES.includes(col.type);
            const toAdd = isFixed
                ? { ...model[col.type], id: col.id }
                : getCustomCell(col);
            return toAdd;
        });

        // if the user is allowed to create new columns, add the last column (with a plus icon)
        if (canEdit)
            cols.push({
                id: defaultColumnsId.ADD_NEW_COLUMN,
            });

        return cols;
    }, [
        orderedColumns,
        results,
        selectedProjectId,
        columnValues,
        sortParams,
        loadingScreenshots,
        tablePositions,
        dynamicColumns,
        loading,
    ]);

    const onAddNewColumn = async (column: NewDynamicColumnInterface) => {
        setLoading(true);

        const payload = {
            project_id: selectedProjectId,
            name: column.name,
            type: column.type,
        };

        const [error, response] =
            await documentServices.createDocumentDynamicProperty(payload);

        delayHandleLoading();

        if (error)
            return toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );

        const { data } = response;

        handleDynamicColumns({
            ...dynamicColumns,
            [data.id]: {
                ...data,
                document_select_options: [],
            },
        });
        getTablePositions();
    };

    return {
        columnsToRender,
        onAddNewColumn,
        handleColumnsOrder,
        orderedColumns,
    };
};

export default useDynamicDocumentInsightsColumn;
