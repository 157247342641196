import { noValueRecordsMap } from '~/utils/parseColumnValues';
import { fixedColumnsMetadata } from '../constants/insightTableColumns';
import { arrayIntersection } from '../utils/arrayOperations';
import {
    AccumulatedColumnValues,
    SortingParameters,
} from '~/interfaces/columnValues/ColumnFilterContext.interface';
import {
    CGDocumentDynamicPropertyInterfaceDetailed,
    DynamicPropertyInterfaceDetailed,
} from '~/interfaces/entities';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';

export const matchFilterKeys = (filters: AccumulatedColumnValues) => {
    const transformedIds = [];
    if (filters.ids) {
        for (const filter of filters.ids) {
            transformedIds.push(...filter.value.split('&'));
        }
    }

    return {
        document_ids: filters[fixedColumnsMetadata.source.key]?.map(
            (d) => d.value
        ),
        page_numbers: filters[fixedColumnsMetadata.page.key]?.map(
            (d) => d.value
        ),
        topic_ids: filters[fixedColumnsMetadata.topic.key]?.map((d) => d.value),
        document_tag_ids: filters[fixedColumnsMetadata.tag.key]?.map(
            (d) => d.value
        ),
        project_ids: filters.project_name?.map((d) => d.value),
        tree_folder_ids: filters.folder_name?.map((d) => d.value),
        ids: transformedIds.length ? transformedIds : undefined,
    };
};

/**
 * By business design, this function fetches all selected values from dynamic column filters and, for different dynamic columns, it applies an INTERSECTION of insight ids.
 *
 * Special case: for multi-select dynamic columns it first applies an intersection within the insight ids selected by the filters in that column first.
 * @param {any} filters applied using the ColumnFilters component in tables. These filters are mixed with fixed and dynamic columns.
 * @param {{type: string, id: string}[]} tableColumns an array of columns metadata (fixed or dynamic) from the backend. Used here to obtain column ids and type.
 * @returns {string[]} intersected insight ids from different dynamic column filters
 */
export const mapDynamicFilters = (
    appliedFilters: AccumulatedColumnValues,
    tableColumns:
        | DynamicPropertyInterfaceDetailed[]
        | CGDocumentDynamicPropertyInterfaceDetailed[],
    columnValues: AccumulatedColumnValues
): string[] => {
    if (!tableColumns.length || !columnValues) return [];

    const filters = { ...appliedFilters };

    const columnTypeMapping: {
        [key: string]: string;
    } = {};

    for (const column of tableColumns) {
        columnTypeMapping[column.id] = column.type;
    }

    const activeDynamicFilters = []; // array of column ids that are active
    for (const filterKey in filters) {
        if (filterKey in columnTypeMapping) {
            activeDynamicFilters.push(filterKey);
        }
    }

    const mappedByColumnFilteredInsightIds = {};
    for (const dynamicPropertyId of activeDynamicFilters) {
        if (
            columnTypeMapping[dynamicPropertyId] ===
            DYNAMIC_PROPERTY_TYPES.MULTI_SELECT
        ) {
            const blankValues =
                columnValues[dynamicPropertyId]?.find(
                    (c) => c.label === noValueRecordsMap.___no_value_records
                )?.value || '';

            const filtersLength = filters[dynamicPropertyId].length;

            const splittedValues = [];
            for (const selectedValue of filters[dynamicPropertyId]) {
                // to prevent mixing blank values with existing values (business logic)
                if (filtersLength > 1 && selectedValue.value === blankValues)
                    continue;

                splittedValues.push(selectedValue.value?.split('&'));
            }

            const reducedIntersection = splittedValues.reduce(
                (accumulator, currentValues) =>
                    arrayIntersection(accumulator, currentValues)
            );

            mappedByColumnFilteredInsightIds[dynamicPropertyId] =
                reducedIntersection;
        } else {
            mappedByColumnFilteredInsightIds[dynamicPropertyId] = [];
            for (const accumulatedValue of filters[dynamicPropertyId]) {
                const transformedValue =
                    accumulatedValue?.value?.split('&') || '';

                mappedByColumnFilteredInsightIds[dynamicPropertyId].push(
                    ...transformedValue
                );
            }
        }
    }

    const insightIdsPerDynamicColumn = [];
    for (const ids of Object.values(mappedByColumnFilteredInsightIds)) {
        insightIdsPerDynamicColumn.push(ids);
    }

    if (insightIdsPerDynamicColumn.length) {
        const intersectedIds = insightIdsPerDynamicColumn.reduce(
            (accumulator, ids) => arrayIntersection(accumulator, ids)
        );

        // if there are no intersected ids, we return a random id to prevent the backend from returning all the results (no ids will be sent instead)
        return intersectedIds.length ? intersectedIds : [crypto.randomUUID()];
    }

    return [];
};

export const extractFilters = (
    filterParameters: AccumulatedColumnValues,
    tableColumns: DynamicPropertyInterfaceDetailed[],
    columnValues: AccumulatedColumnValues
) => {
    const filtersPayload = matchFilterKeys(filterParameters);
    const dynamicallyFilteredIds = mapDynamicFilters(
        filterParameters,
        tableColumns,
        columnValues
    );

    filtersPayload.ids ??= [];
    filtersPayload.ids.push(...dynamicallyFilteredIds);

    return filtersPayload;
};

/**
 * Only sort_attribute or sort_dynamic_property_id can be defined. Used before sending sorting parameters to backend.
 * @param {*} sortingParameters
 * @returns {{sort_order: string, sort_attribute?: string, sort_dynamic_property_id?: string}} Parsed sorting parameters.
 */
export const buildSortingParameters = (
    sortingParameters: SortingParameters
) => {
    if (!sortingParameters?.sortBy && !sortingParameters?.sortDynamicPropertyId)
        return {};

    const base = {
        sort_order: sortingParameters.sortType,
    };

    if (sortingParameters.sortDynamicPropertyId) {
        base.sort_dynamic_property_id = sortingParameters.sortDynamicPropertyId;
    } else {
        base.sort_attribute = sortingParameters?.sortBy;
    }

    return base;
};
