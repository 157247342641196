type Primitive = string | number | undefined | null | boolean;

export const arrayIntersection = (
    a: Primitive[],
    b: Primitive[]
): Primitive[] => {
    const setA = new Set(a);
    const setB = new Set(b);
    const intersection = new Set([...setA].filter((bItem) => setB.has(bItem)));
    return Array.from(intersection);
};
