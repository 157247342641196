export const getQueryLink = (
    options: Record<string, unknown> = {},
    mainUrl: string,
    copyToClipboard = true,
    parameterWrapper = 'params'
) => {
    try {
        const queryString = JSON.stringify(options);
        const encoded = encodeURIComponent(queryString);

        const url = `${window.location.origin + mainUrl}?${parameterWrapper}=${encoded}`;

        if (copyToClipboard) navigator.clipboard.writeText(url);
        return { error: false, url };
    } catch (error) {
        return { error: true, url: '' };
    }
};
